export const constants = {
    customEasing: [.6, .05, -.01, .9],
    durationShort: 1,
    durationMedium: 1.8,
    durationLong: 2.5,
    white: '#ffffff',
    black: '#000000',
    shadowOpacity: 0.2,
}

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const device = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
};

export const COLORS = {
    background: {
        light: '#ffffff',
        dark: '#121212',
    },
    text: {
        light: '#010101',
        dark: '#ffffff',
    },
    red: {
        light: '#ff2345',
        dark: '#ff2345',
    },
    textShadow: {
        light: '#111111',
        dark: '#010101',
    },
    shade1: {
        light: '#efefef',
        dark: '#232323',
    },
    shade2: {
        light: '#e6e6e6',
        dark: '#2b2b2b',
    },
    shade3: {
        light: '#dedede',
        dark: '#343434',
    },
    shade4: {
        light: '#d5d5d5',
        dark: '#3c3c3c',
    },
    shadowOpacity: {
        light: 0.3,
        dark: 0.6,
    },
    textShadowOpacity: {
        light: 1,
        dark: 0.6,
    }
}

export const THEME_MODE_KEY = 'theme-mode';
export const INITIAL_THEME_MODE_CSS_PROP = '--initial-theme-mode';
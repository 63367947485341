import React, { createContext, useReducer, useContext, useEffect } from "react"
import { INITIAL_THEME_MODE_CSS_PROP, THEME_MODE_KEY } from '../constants'

// Define the context
const GlobalStateContext = createContext()
const GlobalDispatchContext = createContext()

// Reducer
const globalReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_THEME': {
            console.log("Theme toggled ekse " + action.theme)
            return {
                ...state,
                currentTheme: action.theme,
            }
        }
        case 'CURSOR_TYPE': {
            return {
                ...state,
                cursorType: action.cursorType,
            }
        }
        default: {
            throw new Error('Unhandled action type: ${action.type}')
        }
    }
}


export const GlobalProvider = ({ children }) => {
    function init() {
        return {
            currentTheme: 'dark',
            cursorType: 'hidden',
            cursorStyles: ['pointer', 'hovered', 'alternate', 'locked', 'hidden']
        } // This is for build defaults
    }

    const [state, dispatch] = useReducer(globalReducer, null, init)

    useEffect(() => {
        const root = window.document.documentElement;
        const initialThemeValue = root.style.getPropertyValue(
            INITIAL_THEME_MODE_CSS_PROP
        );

        dispatch({
            type: 'TOGGLE_THEME',
            theme: initialThemeValue
        })
    }, []);

    return (
        <GlobalDispatchContext.Provider value={dispatch}>
            <GlobalStateContext.Provider value={state}>
                {children}
            </GlobalStateContext.Provider>
        </GlobalDispatchContext.Provider>
    )
}


// Custom hooks to use dispatch and state
export const useGlobalStateContext = () => useContext(GlobalStateContext)
export const useGlobalDispatchContext = () => useContext(GlobalDispatchContext)